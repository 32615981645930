
































































































































































import Vue from 'vue';
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import { CreatorService } from '@/lib/services/meta/creator';

import SearchInput from '@/components/form/SearchInput.vue';
import CardLine from '@/components/CardLine.vue';
import SidebarRecurring from './SidebarRecurring.vue';

import {
  Invoice,
  InvoiceRecurring,
  InvoiceRecurringFrequence,
  InvoiceStatus,
  InvoiceType,
  Profile,
} from '@/models';
import { clamp, parseCsv } from '@/lib/helpers';
import { Currency } from '@/lib/currency';
import { Language } from '@/plugins/i18n';

import InvoiceCreatorConfig from './config';
import InvoiceImportModal from '@/components/modals/InvoiceImportModal.vue';

interface SidebarOption<T> {
  key: string;
  value: T;
}

@Component({
  components: {
    InvoiceImportModal,
    SearchInput,
    CardLine,
    SidebarRecurring,
  },
})
export default class InvoiceCreatorSidebar extends Vue {
  @Prop({ required: true })
  invoice!: Invoice;
  @Prop()
  profile: Profile | null;
  @Prop({ required: true })
  config!: InvoiceCreatorConfig;
  @Prop({ default: false })
  isModifying: boolean;
  @Inject() creatorService!: CreatorService;

  private selectedProfile: Profile | null = null;
  profiles: Profile[] = [];
  loaded: boolean = false;
  csvFile: File | null = null;
  csvRows: any[][] = [];
  showMappingModal: boolean = false;

  $refs: {
    csvInput: HTMLInputElement;
  };

  recurrenceOptions: SidebarOption<InvoiceStatus>[] = [
    { key: 'invoice.types.oneoff', value: InvoiceStatus.DRAFT },
    { key: 'invoice.types.recurring', value: InvoiceStatus.RECURRING },
    { key: 'invoice.types.quotation', value: InvoiceStatus.DRAFT_QUOTATION },
  ];

  paymentTypes: SidebarOption<InvoiceType>[] = [
    { key: 'invoice.paymentTypes.standard', value: InvoiceType.STANDARD },
    { key: 'invoice.paymentTypes.credit', value: InvoiceType.CREDIT },
    {
      key: 'invoice.paymentTypes.collection',
      value: InvoiceType.AUTOMATIC_COLLECTION,
    },
  ];

  currencies: SidebarOption<Currency>[] = [
    { key: 'Euro', value: Currency.EUR },
    { key: 'US Dollar', value: Currency.USD },
    { key: 'Pound Sterling', value: Currency.GBP },
    { key: 'Japanese Yen', value: Currency.JPY },
    { key: 'Australian Dollar', value: Currency.AUD },
    { key: 'Swiss Franc', value: Currency.CHF },
    { key: 'Canadian Dollar', value: Currency.CAD },
    { key: 'Chinese Yuan', value: Currency.CNY },
    { key: 'Russian Rubles', value: Currency.RUB },
    { key: 'Norwegian Krone', value: Currency.NOK },
    { key: 'Swedish Krona', value: Currency.SEK },
    { key: 'Danish Krone', value: Currency.DKK },
  ];

  languages: SidebarOption<Language>[] = [
    { key: this.$tc('_.lang.en'), value: Language.EN },
    { key: this.$tc('_.lang.nl'), value: Language.NL },
    { key: this.$tc('_.lang.fr'), value: Language.FR },
    { key: this.$tc('_.lang.de'), value: Language.DE },
    { key: this.$tc('_.lang.es'), value: Language.ES },
  ];

  selectedRecurrence: SidebarOption<InvoiceStatus> | null = null;

  isRecurring: boolean = false;
  isQuotation: boolean = false;

  async created(): Promise<void> {
    this.initProfiles();

    if (this.invoice.meta.language) {
      this.$emit('language', this.invoice.meta.language);
    }
    if (this.invoice.status) {
      this.selectedRecurrence =
        this.recurrenceOptions.find((e) => e.value === this.invoice.status) ||
        null;
      if (this.selectedRecurrence) {
        this.onRecurrenceChange(this.selectedRecurrence);
      }
    }

    if (this.config.allowedTypes) {
      const allowedTypes = this.config.allowedTypes;
      this.recurrenceOptions = this.recurrenceOptions.filter((e) =>
        allowedTypes.includes(e.value),
      );
    }
    this.loaded = true;
  }

  async initProfiles(): Promise<void> {
    this.profiles = await this.creatorService.listProfiles();
    if (this.profiles) {
      let wantedProfile;
      if (this.invoice.sender) {
        wantedProfile = this.profiles.find(
          (e) => +e.id === +this.invoice.sender.id,
        );
      } else {
        wantedProfile = this.profiles.find((e) => e.isDefault);
      }

      this.selectedProfile = wantedProfile || this.profiles[0];

      if (!this.isModifying && this.selectedProfile) {
        // Pretend the user selects it, so languages etc are set properly
        this.onProfileSelect(this.selectedProfile);
      } else {
        this.$emit('profile', this.selectedProfile);
      }
    }
  }

  onProfileSelect(profile: Profile): void {
    this.$emit('profile', profile);
    if (profile.language) {
      this.invoice.meta.language = profile.language;
      this.$emit('language', this.invoice.meta.language);
    }
  }

  @Watch('profile')
  onParentProfileUpdate(): void {
    this.selectedProfile = this.profile;
  }

  onRecurrenceChange(option: SidebarOption<InvoiceStatus>): void {
    this.invoice.status = option.value;

    // Shadow variables because Vue cannot detect the change inside invoice
    this.isRecurring = this.invoice.isRecurring;
    this.isQuotation = this.invoice.isQuotation;
    this.$emit('update-status');

    if (this.isRecurring) {
      if (!this.invoice.meta.recurring) {
        this.invoice.meta.recurring = new InvoiceRecurring();
      }

      if (!this.invoice.meta.recurring.times) {
        this.invoice.meta.recurring.times = 0;
      }
      if (!this.invoice.meta.recurring.frequence) {
        this.invoice.meta.recurring.setFrequence(
          1,
          InvoiceRecurringFrequence.MONTHLY,
        );
      }
    }
  }

  onDiscountChange(): void {
    this.invoice.discount = clamp(this.invoice.discount, 0, 100);
  }

  onDateChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (this.isRecurring) {
      this.invoice.meta.recurring.startDate = target.valueAsDate!;
    }
  }

  async loadCsv(): Promise<void> {
    this.csvFile = null;
    if (!this.$refs.csvInput.files || this.$refs.csvInput.files.length === 0)
      return;

    try {
      this.csvFile = this.$refs.csvInput.files[0];
      const result = await parseCsv(this.csvFile);
      this.csvRows = result.data;
      this.openImportModal();
    } catch (e) {
      this.csvFile = null;
    }
  }

  openImportModal(): void {
    if (this.csvFile !== null) {
      this.showMappingModal = true;
    }
  }

  get dateTranslation(): string {
    if (this.isRecurring) {
      return this.$tc('invoice.creator.dateRecurring');
    }

    return this.$tc(
      'invoice.creator.date.' + (this.isQuotation ? 'quotation' : 'invoice'),
    );
  }
}
