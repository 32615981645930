







































































































































import Vue from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';

import {
  InvoiceLine,
  LINE_IMPORT_PROPERTIES,
  LINE_IMPORT_TRANSLATIONS,
} from '@/models/invoice/line';
import Big from 'big.js';
import { CreatorService } from '@/lib/services/meta/creator';
import Popover from '@/components/Popover.vue';
import { parseCsv } from '@/lib/helpers';
import { Invoice, InvoiceVAT, LedgerNumber } from '@/models';

interface LineDataObject {
  quantity: string;
  description: string;
  ledger: string;
  vat: string;
  price: string;
}

@Component({
  components: { Popover },
})
export default class InvoiceImportModal extends Vue {
  @Prop({ required: true })
  invoice!: Invoice;

  @Prop({ default: [], type: Array })
  rows: any[][];

  @Prop({ default: [], type: File })
  file: File | null;

  @Inject() creatorService!: CreatorService;

  private csvRows: any[][] = [];
  private csvFile: File | null = null;
  private hasHeaders: boolean = true;
  mapped: any[] = [];
  fields: string[] = LINE_IMPORT_PROPERTIES;
  fieldNames: any = false;

  vatCodes: InvoiceVAT[] = [];
  ledgers: LedgerNumber[] = [];

  $refs: {
    csvInput: HTMLInputElement;
  };

  get dataRows(): any[][] {
    return this.csvRows.slice(+this.hasHeaders);
  }

  get hasData(): boolean {
    return this.csvRows.length > +this.hasHeaders;
  }

  get importIsValid(): boolean {
    return LINE_IMPORT_PROPERTIES.every((f) => this.mapped.includes(f));
  }

  changedMapping(idx: number): void {
    const selected = this.mapped[idx];
    if (!selected) {
      return;
    }

    for (let i = 0; i < this.mapped.length; i++) {
      if (i !== idx && this.mapped[i] === selected) {
        this.mapped[i] = null;
      }
    }
  }

  async loadCsv(): Promise<void> {
    if (!this.$refs.csvInput.files || this.$refs.csvInput.files.length === 0)
      return;

    try {
      this.csvFile = this.$refs.csvInput.files[0];
      const result = await parseCsv(this.csvFile);
      this.csvRows = result.data;
    } catch (e) {
      this.csvFile = null;
    }
  }

  importData(): void {
    const mapping = Object.entries(this.mapped).filter(
      ([, name]) => name !== null,
    );

    const lineDataObjects: LineDataObject[] = this.dataRows.map((row) => {
      const object = {};
      for (const [idx, name] of mapping) {
        object[name] = row[idx];
      }
      return object as LineDataObject;
    });

    let allFound = true;
    const invoiceLines = lineDataObjects.map((line) => {
      const newLine = new InvoiceLine();
      newLine.includesVat = this.invoice.includesVat;

      // Cleanup potential error for decimal point notation
      line.price = String(line.price).replace(',', '.');
      const vat = this.vatCodes.find(
        (vc) => vc.factor === 1 + parseInt(line.vat) / 100,
      );
      const ledger = this.ledgers.find(
        (l) => l.ledgerNumber === String(line.ledger),
      );

      newLine.quantity = new Big(line.quantity ?? 0);
      newLine.description = line.description;
      newLine.vat = vat ?? null;
      newLine.ledger = ledger?.toInvoiceLedger() ?? null;
      newLine.price = new Big(parseFloat(line.price ?? 0));

      if (allFound && (newLine.vat === null || newLine.ledger === null)) {
        allFound = false;
        this.$toaster.error(this.$tc('messages.error.import.invoice'));
      }

      return newLine;
    });

    if (allFound) {
      this.$toaster.success(this.$tc('messages.success.import.invoice'));
    }
    this.$emit('import', invoiceLines);
    this.$emit('close');
  }

  async mounted(): Promise<void> {
    if (this.csvRows.length === 0) {
      this.csvRows = this.rows;
    }
    if (this.csvFile === null) {
      this.csvFile = this.file;
    }
    this.fieldNames = LINE_IMPORT_TRANSLATIONS.map((e) =>
      this.$i18n.tc(`invoice.${e}`),
    );

    this.vatCodes = await this.creatorService.listVatCodes();
    this.ledgers = await this.creatorService.listLedgerNumbers();
  }
}
